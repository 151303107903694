/*!; header ;*/
.b-head {
  z-index: 903;
  background: url("../img/header_top.jpg") repeat-x center;
  height: 156px;
  width: 100%;
  position: relative;

  @include respond(m1){
    height: 80px;
  }

  .b-socials{
    @include respond(s4){
      margin-right: -250px;
    }
    @include respond(m1){
      margin-right: 0;
    }
  }
}

.b-head__wrap{
  @include flex-col;

  @include respond(m1){
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    display: none;
  }
}

.b-logo{
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  margin: 0 0 0 154px;
  z-index: 900;

  @include respond(s0){
    margin-left: 20px;
  }
  @include respond(s3){
    top: 16px;
  }
  @include respond(m1){
    left: 50%;
    margin-left: -90px;
    top: 10px;
  }
  @include respond(m4){
    margin-left: -80px;
  }

  img{
    max-width: 386px;
    width: auto;

    @include respond(s1){
      max-width: 300px;
    }
    @include respond(s3){
      max-width: 280px;
    }
    @include respond(m1){
      max-width: 180px;
    }
    @include respond(m4){
      max-width: 160px;
    }
  }
}

.b-head__top{
  min-height: 103px;
}

.b-head__right{
  @include flex;
  justify-content: flex-end;
  padding-right: 84px;
  width: 100%;

  @include respond(s0){
    padding-right: 20px;
  }
}

.b-search{
  margin: 50px 31px 0 0;
  width: 238px;
  position: relative;

  @include respond(s2){
    width: 180px;
  }

  .b-search__input{
    @extend .reset_shad;
    border: 0;
    background: none;
    border-bottom: 1px solid #707070;
    font: normal 20px $font-fourth;
    height: 22px;
    text-transform: uppercase;
    width: 100%;
    color: $color-primary;
  }

  &:before{
    content: "\e905";
    color: #fff;
    font-family: icomoon;
    font-size: 26px;
    position: absolute;
    top: -1px;
    left: -31px;
  }
}

.tel{
  font: normal 20px/100% $font-sixth;
  margin-right: 103px;
  margin-top: 49px;
  position: relative;

  &:after{
    @include before;
    background: #707070;
    height: 43px;
    width: 1px;
    top: -11px;
    right: -39px;
  }
  &:hover{
    color: $color-blue;
  }
}

.b-socials{
  margin-right: 26px;
  @include flex;

  a{
    margin: 0 0 0 10px;
    height: 33px;
    width: 33px;
    display: block;

    &.fb {
      background: url("../img/fb.svg") no-repeat;
      background-size: 100%;
    }
    &.tw {
      background: url("../img/tw.svg") no-repeat;
      background-size: 100%;
    }
    &.inst {
      background: url("../img/inst.svg") no-repeat;
      background-size: 100%;
    }
    &.in {
      background: url("../img/in.svg") no-repeat;
      background-size: 100%;
    }
    &.yt {
      background: url("../img/yt.svg") no-repeat;
      background-size: 100%;
    }
    &.tt {
      background: url("../img/tt.svg") no-repeat;
      background-size: 100%;
    }
    &.mail{
      background: url("../img/mail.svg") no-repeat;
      background-size: 100%;
    }

    &:hover{
      box-shadow: 0 0 3px 3px $color-blue;
    }
  }
}

.b-head__bottom{
  background: rgba(0,0,0,.69);
  height: 53px;
}

.b-nav{
  @include flex;
  justify-content: flex-end;
  padding-right: 133px;
  height: 53px;

  @include respond(s0){
    padding-right: 20px;
  }
}

.b-nav__link{
  font: normal 20px/100% $font-second;
  text-transform: uppercase;
  margin: 0 0 0 41px;
  height: 53px;
  padding: 16px 0 0;
  box-sizing: border-box;
  position: relative;

  @include respond(s4){
    margin: 0 15px;
    font-size: 18px;
    padding-top: 20px;
  }

  &.active,
  &:hover{
    color: $color-blue;
  }
  &.active{
    cursor: default;

    &:after{
      @include before;
      background: url("../img/menu_active.svg") no-repeat;
      bottom: -12px;
      left: 50%;
      height: 24px;
      width: 28px;
      margin-left: -14px;
    }
  }
}

.burger{
  display: none;
  order: 0;

  @include respond(m1){
    display: block;
    height: 48px;
    width: 52px;
    cursor: pointer;
    position: absolute;
    top: 17px;
    right: 11px;
  }

  span,
  &:before,
  &:after{
    @include before;
    background: $color-primary;
    height: 4px;
    width: 35px;
    left: 8px;
    transition: all .3s ease;
  }
  span{
    top: 13px;
  }
  &:before{
    top: 21px;
  }
  &:after{
    top: 29px;
  }
  &:hover{
    span,
    &:before,
    &:after{
      background: $color-blue;
    }
  }
}

.menu_opened {
  .burger {
    position: fixed;
    z-index: 1001;
    top: 17px;
    background: none;
    border: 0;


    span {
      background: none !important;
    }

    &:before,
    &:after {
      top: 22px;
      left: 9px;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }

    &:hover {
      span,
      &:before,
      &:after {
        background: $color-primary;
      }
    }
  }

  .b-head__wrap{
    @include flex;
    flex-wrap: wrap;
    z-index: 1001;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    overflow-y: auto;
    max-width: 100%;

    .btn_cart{
      display: none;
    }
  }

  .b-logo{
    display: none;
  }

  .b-head__top{
    order: 2;
    margin-bottom: auto;
    width: 100%;
  }

  .b-head__bottom{
    order: 1;
    height: auto;
    width: 100%;
    margin-bottom: auto;
    padding-bottom: 40px;
  }

  .b-nav{
    @include flex-col;
    height: auto;
    padding-right: 0;
    display: block;
    text-align: center;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }

  .b-nav__link{
    display: block;
    margin: 0;

    &.active{
      &:after {
        display: none;
      }
    }
  }

  .spr{
    position: static;
  }

  .label{
    z-index: 1;
  }

  .b-head__right{
    justify-content: center;
    padding-right: 0;
    @include flex-col;
  }

  .b-socials{
    margin-right: auto;
    margin-left: auto;

    a{
      margin: 0 5px;
    }
  }

  .tel{
    text-align: center;
    margin-right: 0;
    margin-top: 30px;
  }

  .b-search{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 10px;
    margin-top: 30px;
  }

}

