/*!; normalize.css ;*/
html, body {
  height: 100%;
}

body {
  @include base-indent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
}


table, tr, td {
  border: none;
  @include base-indent;
}


table {
  border-collapse: collapse;
}


img, button {
  border: 0px;
  outline: none;
}


p, div, h1, h2, h3, h4, h5, h6, fieldset, form, input, textarea, header, nav, section, article, aside, footer {
  @include base-indent;
  display: block;
  box-sizing: border-box;
}


ul, li, ol {
  list-style-type: none;
  @include base-indent;
}

fieldset {
  border: none;
}

textarea {
  overflow: auto;
  outline: none;
  resize: none;
}


input, select, area {
  outline: none;
}


input [type="submit"]::-moz-focus-inner,
input [type="button"]::-moz-focus-inner {
  padding: 0;
  border: 0;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}


::-ms-clear {
  display: none;
}


input:not[type="radio"],
input[type="button"],
input[type="submit"],
button {
  -webkit-appearance: none !important;
  border-radius: 0px !important;
}