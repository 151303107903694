/*!; footer ;*/
.b-footer {
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  background: url("../img/footer_bg.jpg") no-repeat top center;
  background-size: cover;

  .b-wrap{
    max-width: 1728px;
  }

  .b-logo{
    position: relative;
    top: 0;
    left: 5px;
    margin: 0 auto 13px;
    display: block;

    @include respond(m4){
      left: -2px;
    }

    img{
      max-width: 296px;
    }
  }

  .b-socials{
    flex-wrap: wrap;
    max-width: 300px;
    margin-right: 0;
    justify-content: center;

    @include respond(m3){
      margin-left: auto;
      margin-right: auto;
    }

    a{
      height: 37px;
      width: 37px;
      margin: 0 6px 6px;
      padding-bottom: 0;

      @include respond(m5){
        margin-right: 2px;
        margin-left: 2px;
      }
    }

    span{
      display: block;
      border-top: 3px solid $color-light-blue;
      border-bottom: 3px solid $color-light-blue;
      font: normal 19px $font-second;
      max-width: 298px;
      margin: 0 auto 23px;
    }
  }
}

.b-footer__top{
  padding: 9px 0 38px 123px;
  @include flex;

  @include respond(s2){
    padding-right: 20px;
    padding-left: 20px;
  }
  @include respond(m1){
    flex-wrap: wrap;
    padding-left: 0;
  }
  @include respond(m3){
    padding-right: 0;
    padding-bottom: 0;
  }
}

.b-footer__col{
  min-width: 229px;
  position: relative;

  @include respond(s1){
    min-width: auto;
    width: 25%;
  }
  @include respond(m1){
    width: 50%;
    padding-bottom: 30px;
    padding-left: 20px;
  }
  @include respond(m3){
    width: 100%;
    @include flex-col;
    justify-content: center;
    padding-left: 0;
    text-align: center;
    padding-bottom: 10px;
  }

  h5{
    position: relative;
    left: -5px;
    padding-bottom: 41px;

    @include respond(m3){
      padding-bottom: 20px;
      left: 0;
    }
  }

  a{
    display: block;
    font: normal 13px/100% $font-sixth;
    padding-bottom: 8px;

    @include respond(m3){
      padding-bottom: 14px;
    }
  }

  &.b-footer__col_nav{
    margin: 25px 0 0 71px;

    @include respond(s3){
      margin-left: 30px;
    }
    @include respond(m1){
      margin-left: 0;
    }
    @include respond(m3){
      margin-top: 15px;
    }

    &:after{
      @include before;
      background: rgba(112,112,112,.45);
      top: 52px;
      right: 0;
      width: 3px;
      height: 159px;

      @include respond(m1){
        display: none;
      }
    }
  }

  &.b-footer__col_contacts{
    margin: 25px 0 0 93px;

    @include respond(s3){
      margin-left: 30px;
    }
    @include respond(m1){
      margin-left: 0;
    }
    @include respond(m3){
      max-width: 200px;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
      margin-top: 15px;
    }

    h5{
      padding-bottom: 36px;

      @include respond(m3){
        text-align: center;
        padding-bottom: 20px;
      }
    }
  }

  &.b-footer__col_logo{
    margin-right: 82px;
    min-width: 297px;

    @include respond(s2){
      margin-right: 30px;
    }
    @include respond(m1){
      margin-right: 0;
    }
    @include respond(m4){
      min-width: 270px;
    }
  }

  &:nth-child(3){
    min-width: 263px;

    @include respond(s2){
      min-width: auto;
    }
  }
}

.b-footer__addr,
.b-footer__tel,
.b-footer__mail{
  display: block;
  padding: 0 0 27px 26px;
  position: relative;
  font: normal 13px/160% $font-sixth;

  i{
    color: $color-primary;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.b-footer__addr{
  max-width: 136px;

  i{
    top: 1px;
    font-size: 15px;
  }
}

.b-footer__tel{
  padding-bottom: 23px;

  i{
    font-size: 15px;
    top: -2px;
  }
}

.b-footer__mail{
  i{
    font-size: 15px;
  }
}

.b-footer__bottom{
  border-top: 2px solid rgba(56,56,56,.3);
  padding: 14px 293px 13px 147px;
  @include flex;
  color: #BCC0C4;

  @include respond(s0){
    padding-right: 20px;
  }
  @include respond(s2){
    padding-left: 20px;
  }
  @include respond(m3){
    @include flex-col;
    justify-content: center;
    padding: 15px 0 10px;
  }
}

.copy{
  font: normal 15px/100% $font-eighth;
  padding-bottom: 0;
  padding-top: 4px;

  @include respond(m3){
    padding-bottom: 30px;
    text-align: center;
  }
}

.b-footer__links{
  margin-left: auto;

  @include respond(m3){
    margin-right: auto;
    text-align: center;
  }

  a{
    font: normal 15px/100% $font-eighth;
    margin: 0 18px;
    color: #BCC0C4;

    &:hover{
      color: $color-blue;
    }

    @include respond(m3){
      display: block;
      margin: 0 0 15px;
    }
  }

  span{
    @include respond(m3){
      display: none;
    }
  }
}