/*!; base ;*/
body {
  background: $color-primary;
  color: $color-primary;
  font-size: $fontsize-base;
  font-weight: normal;
  line-height: 180%;
  font-family: $font-primary;
  @include base-indent;
}

h1{
  font: normal 51px/95% $font-second;
  text-transform: uppercase;
  padding-bottom: 17px;

  @include respond(m1){
    font-size: 45px;
  }
  @include respond(m3){
    font-size: 38px;
  }

  span{
    display: block;
    font: bold 36px/120% $font-third;
    padding-bottom: 25px;
    position: relative;
    left: -3px;

    @include respond(m3){
      font-size: 26px;
    }
  }
}

h2{
  font: normal 41px/100% $font-second;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
  @include display_inline_block;

  @include respond(m1){
    font-size: 35px;
  }
  @include respond(m3){
    font-size: 26px;
  }

  &:before,
  &:after{
    @include before;
    background: #385864;
    height: 3px;
    width: 70px;
    left: -81px;
    top: 18px;

    @include respond(m1){
      top: 15px;
    }
    @include respond(m3){
      top: 10px;
    }
  }
  &:after{
    left: auto;
    right: -81px;
  }
}

h3 {
  font: normal 20px/100% $font-second;
  text-transform: uppercase;
  color: #B2C7C7;
}

h4{
  font: normal 20px/100% $font-second;
}

h5{
  color: #B2C7C7;
  font: normal 20px/120% $font-second;
  text-transform: uppercase;
}

h6{

}

a {
  color: $color-primary;
  outline: none;
  text-decoration: none;
  transition: all .3s ease;

  &:hover,
  &:focus {
    color: #B2C7C7;
  }
}

p{
  padding-bottom: 15px;
}

ul{
  li{
    padding-bottom: 15px;
  }
}

img{
  display: block;
}

section,
.section{
  text-align: center;
  padding: 33px 0;
}

.b-row{
  @include flex;
}

.reset_shad{
  -webkit-appearance: none;
  border-radius: 0;
}

///// buttons /////

.btn{
  @extend .reset_shad;
  height: 61px;
  background: #385864;
  text-align: center;
  @include display_inline_block;
  padding: 0 34px;
  font: bold 19px/61px $font-fifth;
  color: $color-primary;
  text-transform: uppercase;
  position: relative;
  border-radius: 3px;

  @include respond(m3){
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    padding: 0 20px;
  }

  &:hover{
    color: $color-primary;
    background: #2B3738;
  }
}

.btn_cart{
  height: 60px;
  font: bold 22px/60px $font-sixth;
  color: #DFDFDF;
  padding-top: 2px;
  box-sizing: border-box;
  position: relative;

  &:before{
    @include before;
    background: url("../img/cart.svg") no-repeat;
    height: 33px;
    width: 42px;
    position: relative;
    top: 10px;
    left: -3px;
    @include display_inline_block;
    margin-right: 15px;
  }
  span.pr-num {
    bottom: 5px;
    color: #dfdfdf;
    position: absolute;
    right: 5px;
    height: auto;
    font-size: 14px;
    line-height: normal;
  }
  &.mob{
    width: 50px;
    height: 40px;
    line-height: 40px;
    text-indent: 9999px;
    overflow: hidden;
    padding: 0;
    display: none;
    z-index: 900;
    span.pr-num {
      bottom: 3px;
      color: #dfdfdf;
      position: absolute;
      right: 3px;
      height: auto;
      font-size: 12px;
      line-height: normal;
    }

    @include respond(m1){
      @include display_inline_block;
      position: absolute;
      top: 21px;
      left: 20px;
    }

    &:before{
      left: 7px;
      height: 20px;
      width: 25px;
      position: absolute;
      background-size: 100%;
    }
  }
  
}


.btn_all{
  background: none;
  border: 3px solid #2B3738;
  color: #B2C7C7;
  height: 63px;
  font: 600 20px/58px $font-third;
  min-width: 234px;
  box-sizing: border-box;

  @include respond(m3){
    height: 53px;
    line-height: 48px;
    font-size: 15px;
  }
}

.btn_buy{
  background: #86c6c7;
  height: 59px;
  font: bold 19px/59px $font-sixth;
  width: 80px;
  padding: 0;
  &.sold-out,
  &.btn-buy-sold-out {
    cursor: not-allowed;
    background: #1a282d;
  }

  @include respond(m3){
    height: 49px;
    line-height: 49px;
    font-size: 15px;
    width: 60px;
  }

  &:hover{
    background: $color-blue;
    color: $color-primary;
  }
}

.btn_light{
  border-color: #E4E4E4;
  color: #B2C7C7;

  &:hover{
    background: #E4E4E4;
    color: $color-blue;
  }
}

.btn_big{
  min-width: 319px;
  box-sizing: border-box;

  @include respond(m3){
    min-width: auto;
  }
}

.btn_sm{
  color: #618896;
  font: normal 11px $font-second;
  text-transform: uppercase;
  border: 3px solid #B6C6C7;
  height: 26px;
  line-height: 26px;
  background: none;
  padding: 0 9px;

  &:hover{
    border-color: #2B3738;
  }
}

.btn_send,
.btn_details{
  color: $color-primary;
  font: normal 18px/64px $font-second;
  text-transform: uppercase;
  border: 3px solid #B6C6C7;
  height: 64px;
  background: none;
  padding: 0 34px;
  cursor: pointer;
  @include transition;
}

.btn_details{
  min-width: 263px;
  height: 63px;
  line-height: 63px;
  box-sizing: border-box;

  @include respond(m1){
    min-width: auto;
  }
}

///// ---buttons--- /////

.b-input{
  @extend .reset_shad;
  background: rgba(97,136,150,.22);
  border: 1px solid rgba(147,147,147,.22);
  color: $color-primary;
  font: normal 16px $font-primary;
  height: 50px;
  padding: 0 18px;
  margin-bottom: 30px;
  width: 100%;

  @include respond(m2){
    padding: 0 10px;
    font-size: 15px;
  }
  @include respond(m4){
    margin-bottom: 15px;
  }

  &:focus{
    border-color: #000;
  }
}

.b-textarea{
  @extend .b-input;
  height: 111px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.has-danger{
  border-color: red !important;
}

.b-wrapper{
  overflow: hidden;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 320px;
}

.b-wrap{
  width: 100%;
  max-width: 1412px;
  margin: 0 auto;
  position: relative;

  @include respond(s1){
    padding-left: 20px;
    padding-right: 20px;
  }
}

::-webkit-input-placeholder {color:$color-primary;}
::-moz-placeholder          {color:$color-primary;}
:-moz-placeholder           {color:$color-primary;}
:-ms-input-placeholder      {color:$color-primary;}
:focus::-webkit-input-placeholder {color: transparent}
:focus::-moz-placeholder          {color: transparent}
:focus:-moz-placeholder           {color: transparent}
:focus:-ms-input-placeholder      {color: transparent}
