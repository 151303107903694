.b-sidebar{
  width: 302px;
  position: relative;
  margin-left: -28px;
  padding-bottom: 30px;

  @include respond(s2){
    margin-left: 0;
    margin-right: 20px;
    float: left;
  }
  @include respond(s4){
    width: 220px;
  }
  @include respond(m3){
    width: 100%;
    margin-right: 0;
    @include flex;
    flex-wrap: wrap;
    justify-content: center;
    float: none;
    padding-bottom: 20px;
  }
  &.categories-sidebar {
    @include respond(m3){
      display: none;
    }
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-primary;
      font: normal 14px/120% $font-second;
      text-transform: uppercase;
      padding: 5px 25px;
      border: 3px solid #3B4546;
      background: rgba(56,88,100,.57);
      border-radius: 5px;
      margin: 0 0 7px;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      @include respond(m2){
        padding: 14px 15px;
        min-height: auto;
      }
      @include respond(m3){
        font-size: 12px;
        padding: 6px 8px 4px;
        min-height: auto;
        margin: 0 5px 10px;
      }
      &:hover, &.active {
        background: #B6C6C7;
        border-color: #3B4546;
        color: #242424;
      }
      &.active {
        cursor: default;
        &:after{
          @include before;
          background: url("../img/corner.svg") no-repeat;
          height: 24px;
          width: 24px;
          top: 50%;
          margin-top: -12px;
          right: -31px;
      
          @include respond(m3){
            display: none;
          }
        }
      }
    }
  }
}
.product-categories-container {
  display: none;
  @include respond(m3){
    display: block;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #86c6c7;
  }
}

// .b-sidebar__link{
//   position: absolute;
//   opacity: 0;
// }

// .b-sidebar__link + label{
//   @include flex;
//   justify-content: center;
//   align-items: center;
//   color: $color-primary;
//   font: normal 20px/120% $font-second;
//   text-transform: uppercase;
//   padding: 18px 25px 15px;
//   border: 3px solid #3B4546;
//   background: rgba(56,88,100,.57);
//   border-radius: 5px;
//   margin: 0 0 20px;
//   position: relative;
//   min-height: 80px;
//   box-sizing: border-box;
//   cursor: pointer;

//   @include respond(m2){
//     padding: 14px 15px;
//     min-height: auto;
//   }
//   @include respond(m3){
//     font-size: 12px;
//     padding: 6px 8px 4px;
//     min-height: auto;
//     margin: 0 5px 10px;
//   }

//   &:hover{
//     background: #B6C6C7;
//     border-color: #3B4546;
//     color: #242424;
//   }
// }

// .b-sidebar__link:checked + label{
//   background: #B6C6C7;
//   border-color: #3B4546;
//   color: #242424;
//   cursor: default;

//   &:after{
//     @include before;
//     background: url("../img/corner.svg") no-repeat;
//     height: 24px;
//     width: 24px;
//     top: 50%;
//     margin-top: -12px;
//     right: -31px;

//     @include respond(m3){
//       display: none;
//     }
//   }
// }

.b-sidebar__news{
  width: 266px;
  min-width: 266px;
  margin-left: auto;
  text-align: left;
  margin-right: 44px;
  padding-top: 37px;
  padding-left: 40px;
  margin-bottom: auto;

  @include respond(s4){
    margin-right: 0;
  }
  @include respond(m3){
    order: 0;
    width: 100%;
    padding-left: 0;
    @include flex-col;
    padding-top: 0;
  }

  h4{
    font: bold italic 36px/120% $font-ninth;
    padding-bottom: 56px;

    @include respond(m3){
      font-size: 20px;
      padding-bottom: 20px;
    }
  }

  a{
    display: block;
    color: #B2C7C7;
    font: bold 21px/100% $font-primary;
    margin: 0 0 29px;

    @include respond(m3){
      font-size: 16px;
      margin: 0 0 17px;
    }

    &:hover,
    &.active{
      color: $color-primary;
    }
    &.active{
      cursor: default;
    }
  }
}

