/*!; content ;*/
.b-main {
    display: block;
    flex: 1 0 auto;
    background: url("../img/bg-new.jpg") no-repeat top center;
    background-size: cover;
}

.b-slider-wrap {
    position: relative;
}

.loading {
    background: url("../img/loading.svg") no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -42px;
    margin-top: -42px;
    height: 84px;
    width: 84px;
}

.spr {
    background: url("../img/spr.png") no-repeat top center;
    background-size: 100% 19px;
    height: 19px;
    width: 100%;
    position: relative;
    z-index: 904;
}

.b-slider {
    opacity: 0;
    overflow: hidden;

    .btn {
        margin-right: auto;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 100%;

        > div {
            display: flex;
            margin-bottom: 0;
            height: 100%;
            width: 100%;
        }
    }

    &.large {
        height: 542px;

        @include respond(m3) {
            min-height: 542px;
            height: auto;
        }


        .b-slider__item {
            min-height: 542px;
            height: 100%;
        }
    }

    &.default {
        height: 391px;

        @include respond(m3) {
            min-height: 220px;
            height: auto;
        }


        .b-slider__item {
            min-height: 391px;
            height: 100%;

            @include respond(m3) {
                min-height: 220px;
            }
        }

        .b-wrap {
            padding-bottom: 20px;
            padding-top: 20px;

            @include respond(m3) {
                padding-bottom: 30px;
            }
        }
    }

    &.small {
        height: 249px;

        @include respond(m3) {
            min-height: 220px;
            height: auto;
        }


        .b-slider__item {
            min-height: 249px;
            height: 100%;

            @include respond(m3) {
                min-height: 220px;
            }
        }

        .b-wrap {
            padding-bottom: 15px;
            padding-top: 15px;
        }
    }

    &.smallest {
        height: 184px;

        @include respond(m3) {
            min-height: 184px;
            height: auto;
        }


        .b-slider__item {
            min-height: 184px;
            height: 100%;

            @include respond(m3) {
                min-height: 184px;
            }
        }

        .b-wrap {
            padding-bottom: 15px;
            padding-top: 15px;
        }
    }

    .b-wrap {
        padding-bottom: 54px;
        padding-top: 50px;
    }
}

.b-slider__content {
    position: relative;

    @include flex-col;

    @include respond(s0) {
        margin-top: 0;
    }


    @include respond(s2) {
        left: 50px;
    }


    @include respond(m3) {
        left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }


    p {
        padding-bottom: 28px;
        max-width: 570px;

        @include respond(m3) {
            line-height: 160%;
        }
    }
}

.b-slider__item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;

    .b-wrap {
        position: relative;
        top: 6px;

        @include transition;

        @include respond(s2) {
            left: 0;
            margin-left: 0;
            top: 0;
        }
    }
}

.b-slogan {
    background: #385864;
    text-align: left;
    padding-bottom: 31px;

    p {
        font: normal 19px / 160% $font-sixth;
        padding-bottom: 0;
        padding-left: 17px;

        @include respond(m3) {
            font-size: 15px;
            padding-left: 0;
        }
    }

    .b-wrap {
        max-width: 1400px;
        position: relative;
        padding-right: 360px;

        @include respond(m3) {
            padding-right: 230px;
        }


        @include respond(m4) {
            padding-right: 20px;
        }
    }
}

.label {
    position: absolute;
    right: 23px;
    top: -122px;
    z-index: 901;

    @include respond(m1) {
        right: 20px;
        width: 240px;
        top: 50%;
        margin-top: -109px;
    }


    @include respond(m3) {
        width: 200px;
        margin-top: -90px;
    }


    @include respond(m4) {
        width: 100px;
        top: 0px;
        margin-top: 0;
        margin-left: 15px;
        margin-bottom: 10px;
        position: static;
        float: right;
    }
}

.b-services {
    padding-top: 59px;

    @include respond(s1) {
        padding-left: 20px;
        padding-right: 20px;
    }


    @include respond(m3) {
        padding-top: 33px;
    }
}

.b-services__list {
    @include flex;

    justify-content: center;
    padding-top: 19px;
    padding-bottom: 40px;

    @include respond(m1) {
        flex-wrap: wrap;
    }


    @include respond(m3) {
        padding-bottom: 20px;
    }
}

.b-services__item {
    @include flex-col;

    width: 415px;
    position: relative;
    margin: 0 12px;

    @include respond(s0) {
        width: 23%;
        margin: 0 1%;
    }


    @include respond(m1) {
        width: 48%;
        margin-bottom: 2%;
    }


    @include respond(m3) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 15px;
    }


    img {
        width: 100%;
    }

    &:hover {
        .b-services__logo {
            opacity: 1;
        }

        .b-services__shadow {
            opacity: 1;
        }

        .b-services__name {
            top: 175px;
            color: $color-primary;

            @include respond(s3) {
                top: 140px;
            }


            @include respond(m1) {
                top: 170px;
            }


            @include respond(m5) {
                top: 160px;
            }
        }

        .b-services__details {
            opacity: 1;
        }
    }
}

.b-services__title {
    background: url("../img/service_bg.jpg") no-repeat;
    width: 415px;
    min-height: 87px;
    display: block;
    text-transform: uppercase;
    font: normal 26px / 100% $font-fourth;
    padding: 20px 15px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: -31px;

    @include flex;

    align-items: center;
    justify-content: center;
    z-index: 902;

    @include respond(s0) {
        width: 100%;
        font-size: 22px;
        background-size: cover;
    }
}

.b-services__name {
    @include transition;

    position: relative;
    top: 0;
}

.b-products {
    background: #385864;
    padding-top: 47px;

    @include respond(m3) {
        padding-top: 33px;
    }


    h2 {
        &:before,
        &:after {
            background: #7394a0;
        }
    }
}

.b-products__list {
    @include flex;

    // justify-content: center;
    padding: 43px 0 30px;
}

.b-products__slider {
    padding-bottom: 57px;

    @include respond(s0) {
        padding-left: 50px;
        padding-right: 50px;
    }


    @include respond(m2) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 30px;
    }


    .b-products__item {
        @include respond(s0) {
            margin: 0 auto;
        }


        @include respond(m3) {
            width: auto;
            padding-left: 5px;
            padding-right: 5px;
            max-width: 300px;
        }
    }
}

.bread-crumbs {
    margin: 0 19px 19px;
    text-align: left;
}

h1.category-title {
    font-size: 31px;
    margin: 0 19px 19px;
    text-align: left;
}

.b-products__item,
.b-collections__item {
    width: 318px;
    text-align: left;
    margin: 0 19px;

    img {
        width: 100%;
        height: 230px;
        object-fit: cover;
    }
}

.backlink-container {
    text-align: left;
    margin: 0 19px 19px;

    a {
        font-family: $font-third;
        font-size: 19px;
        display: inline-flex;
        align-items: center;

        i {
            margin-right: 7px;
            font-size: 0.8rem;
        }
    }
}

.b-collections__item {
    font: 500 19px $font-third;
}

.b-products__info {
    font: 500 19px $font-third;
    position: relative;
    padding: 14px 90px 0 0;

    @include respond(m3) {
        padding-right: 70px;
    }


    strong {
        display: block;
        position: relative;
        color: #b2c7c7;
        font: normal 26px $font-second;
        padding: 12px 0 0;

        &:before {
            @include before;

            background: #2b3738;
            height: 3px;
            width: 70px;
            top: 6px;
            left: 0;
        }
    }

    .btn {
        position: absolute;
        top: 6px;
        right: 0;
    }
}

.b-products__name,
.b-collections__name {
    max-width: 184px;
    display: block;
    line-height: 105%;
}

.b-products__img {
    overflow: hidden;
    height: 284px;
    a.sold-out {
        position: relative;
        &:after {
            content: "SOLD OUT";
            display:block;
            position: absolute;
            z-index: 5;
            right: 0;
            top: 0;
            font-size: 10px;
            font-weight: 700;
            background-color: #1a282d;
            padding: 5px 10px;
            color: white;
            border: 1px solid white;
        }
    }

    @include respond(m3) {
        height: auto;
    }
}

.b-instagram {
    background: #242424;
    padding-top: 28px;
    padding-bottom: 57px;

    @include respond(s1) {
        padding-left: 20px;
        padding-right: 20px;
    }


    @include respond(m3) {
        padding-bottom: 20px;
    }
}

.b-instagram__row {
    @include flex;

    flex-wrap: wrap;
    max-width: 1510px;
    margin: 0 auto;
    justify-content: center;
    padding: 28px 0 19px;

    @include respond(m2) {
        padding-bottom: 30px;
    }
}

.b-instagram__item {
    width: 229px;
    height: 229px;
    overflow: hidden;
    margin: 0 11px 22px;

    @include respond(m2) {
        width: 31.333%;
        margin: 0 1% 2%;
        height: auto;
    }


    @include respond(m4) {
        width: 48%;
    }


    img {
        width: 100%;
    }
}

.social-links {
    @include flex;

    justify-content: center;

    @include respond(m1) {
        flex-wrap: wrap;
        max-width: 500px;
        margin: 0 auto;
    }
}

.social-links__item {
    width: 170px;

    @include flex-col;

    justify-content: center;
    margin: 0 42px;

    @include respond(m1) {
        width: 50%;
        margin: 0 0 30px;
    }


    @include respond(m5) {
        width: 100%;
    }


    span {
        display: block;
        font: normal 20px / 100% $font-second;
        text-transform: uppercase;
        padding: 0 0 15px;
    }

    a {
        height: 80px;
        width: 80px;
        display: block;
        margin: 0 auto;

        &.yt {
            background: url("../img/yt_big.svg") no-repeat;
        }

        &.fb {
            background: url("../img/fb_big.svg") no-repeat;
        }

        &.inst {
            background: url("../img/inst_big.svg") no-repeat;
        }

        &.tt {
            background: url("../img/tt_big.svg") no-repeat;
        }
    }
}

.b-resources {
    background: url("../img/news_bg.jpg") no-repeat;
    background-size: cover;
    padding-bottom: 58px;

    @include respond(s1) {
        padding-left: 20px;
        padding-right: 20px;
    }


    @include respond(m3) {
        padding-bottom: 20px;
    }


    h2 {
        color: $color-primary;

        &:before,
        &:after {
            background: #2b3738;
        }
    }
}

.b-resources__row {
    @include flex;

    justify-content: center;
    padding: 28px 0 0;

    @include respond(m3) {
        @include flex-col;
    }
}

.b-resources__item {
    width: 526px;

    @include flex;

    justify-content: center;
    align-items: flex-end;
    border-right: 3px solid rgba(56, 56, 56, 0.63);
    height: 193px;

    @include respond(s2) {
        width: 33.333%;
        height: auto;
    }


    @include respond(m3) {
        width: 100%;
        padding-bottom: 30px;
        border-right: 0;
    }


    &:last-child {
        border-right: 0;
    }

    strong {
        position: relative;
        display: block;
        color: #b2c7c7;
        font: 600 28px / 100% $font-third;
        text-transform: uppercase;
        max-width: 254px;
        margin: 0 auto;

        @include transition;

        @include respond(m1) {
            font-size: 22px;
        }
    }

    span {
        display: block;
        font-family: icomoon;
        color: #b2c7c7;
        margin-bottom: 18px;

        &.icon-graphs {
            &:before {
                content: "\e903";
                font-size: 93px;
                line-height: 120%;

                @include respond(m1) {
                    font-size: 70px;
                }
            }
        }

        &.icon-documentation {
            margin-bottom: 28px;

            &:before {
                content: "\e902";
                font-size: 110px;
                line-height: 120%;

                @include respond(m1) {
                    font-size: 80px;
                }
            }
        }

        &.icon-sale {
            margin-bottom: 20px;

            &:before {
                content: "\e904";
                font-size: 130px;
                line-height: 120%;
                left: -15px;
                position: relative;

                @include respond(m1) {
                    font-size: 90px;
                }
            }
        }
    }

    &:hover {
        strong {
            color: $color-primary;
        }
    }
}

.b-customers {
    background: #385864;
    padding-top: 30px;

    .btn {
        border-color: #6a8794;
        color: #b2c7c7;

        &:hover {
            background: #6a8794;
            color: $color-primary;
        }
    }

    h2 {
        color: #b2c7c7;

        &:before,
        &:after {
            display: none;
        }
    }
}

.b-customers__list {
    @include flex;

    flex-wrap: wrap;
    justify-content: center;
    padding: 14px 0 0;
    max-width: 1618px;
    margin: 0 auto;

    @include respond(s0) {
        max-width: 1300px;
    }


    @include respond(s2) {
        max-width: 100%;
        padding-right: 50px;
        padding-left: 50px;
    }


    @include respond(m1) {
        max-width: 600px;
    }
}

.b-customers__slider {
    flex-wrap: nowrap;

    .slick-list {
        padding-bottom: 40px;
    }
}

.b-customers__item {
    width: 500px;
    margin: 0 auto;
    display: block;
    position: relative;

    @include respond(s4) {
        width: 400px;
    }


    @include respond(m3) {
        width: auto;
        max-width: 280px;
    }


    @media only screen and (max-width: 400px) {
        margin-left: 5px;
        margin-right: 5px;
    }

    &:before {
        @include before;

        background: url("../img/shadow.png") no-repeat;
        bottom: -78px;
        left: 50%;
        margin-left: -223px;
        width: 446px;
        height: 158px;

        @include respond(m3) {
            display: none;
        }
    }

    &:hover {
        .b-customers__shadow {
            opacity: 1;
        }

        .b-customers__name {
            top: -183px;
            z-index: 902;

            @include respond(s4) {
                top: -120px;
            }


            @include respond(m3) {
                top: -100px;
            }


            @include respond(m5) {
                top: -70px;
            }
        }

        .b-customers__logo,
        .b-customers__details {
            opacity: 1;
        }
    }
}

.b-customers_all {
    background: none;
    padding-top: 55px;
    padding-bottom: 11px;

    @include respond(m3) {
        padding-top: 33px;
    }
}

.b-customers__list:not(.b-customers__slider) {
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;

    @include respond(m3) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0;
    }


    .b-customers__item {
        margin: 0 20px 33px;

        @include respond(m1) {
            width: 31.333%;
            margin-left: 1%;
            margin-right: 1%;
            margin-bottom: 2%;
        }


        @include respond(m2) {
            width: 48%;
        }


        @include respond(m3) {
            padding-left: 0;
            padding-right: 0;
            max-width: none;
        }


        @include respond(m6) {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 20px;
        }


        &:hover {
            .b-customers__name {
                @include respond(m3) {
                    top: -90px;
                }


                @include respond(m6) {
                    top: -110px;
                }


                @include respond(m5) {
                    top: -80px;
                }
            }
        }
    }
}

.b-customers__shadow,
.b-services__shadow {
    background: rgba(14, 37, 39, 0.92);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 901;
    top: 0;
    left: 0;
    display: block;
    opacity: 0;

    @include transition;
}

.b-customers__logo,
.b-services__logo {
    position: absolute;
    top: -57px;
    left: 50%;
    width: 106px;
    margin-left: -53px;
    z-index: 902;
    opacity: 0;

    @include transition;
}

.b-services__logo {
    max-width: 106px;
    top: 118px;

    @include respond(s3) {
        top: 90px;
    }


    @include respond(m1) {
        top: 120px;
    }


    @include respond(m5) {
        top: 100px;
    }
}

.b-customers__img {
    overflow: hidden;
    display: block;

    img {
        max-width: 100%;
        object-fit: cover;
    }
}

.b-customers__bottom {
    background: url("../img/customers_bg.jpg") no-repeat;
    background-size: cover;
    height: auto;
    display: block;
    min-height: 111px;
    width: 500px;
    z-index: 902;
    position: relative;

    @include respond(s4) {
        width: auto;
    }


    @include respond(m3) {
        min-height: 79px;
    }
}

.b-customers__name {
    font: normal 27px / 120% $font-fourth;
    height: 111px;

    @include flex-col;

    justify-content: center;
    text-transform: uppercase;
    position: relative;
    top: -5px;

    @include transition;

    @include respond(m1) {
        font-size: 24px;
    }


    @include respond(m3) {
        font-size: 20px;
        height: auto;
        padding: 15px 10px;
    }


    @include respond(m5) {
        font-size: 18px;
        top: 0;
    }


    i {
        display: block;
        font: bold italic 21px / 100% $font-seventh;
        text-transform: none;
        padding-top: 8px;

        @include respond(m1) {
            font-size: 18px;
        }


        @include respond(m3) {
            font-size: 17px;
        }


        @include respond(m5) {
            font-size: 15px;
            padding-top: 4px;
        }
    }
}

.b-customers__details,
.b-services__details {
    color: #b2c7c7;
    font: normal 16px / 111px $font-third;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 111px;

    @include flex;

    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 902;

    @include transition;

    span {
        @include display_inline_block;

        position: relative;

        &:before,
        &:after {
            @include before;

            background: #2b3738;
            height: 3px;
            width: 62px;
            left: -76px;
            top: 53px;

            @include respond(s4) {
                width: 30px;
                left: -40px;
            }
        }

        &:after {
            left: auto;
            right: -76px;

            @include respond(s4) {
                right: -40px;
            }
        }
    }

    @include respond(m3) {
        height: 79px;
    }
}

.b-services__details {
    height: 87px;
}

.b-news {
    background: url("../img/news_bg.jpg") no-repeat top center;
    background-size: cover;
    padding-top: 70px;

    @include respond(m3) {
        padding-top: 33px;
    }


    h2 {
        color: #b2c7c7;

        &:before,
        &:after {
            background: #b6c6c7;
        }
    }
}

.link-box {
    max-width: 1400px;
    margin: -37px auto 0;

    @include flex;

    justify-content: flex-end;
    position: relative;

    @include respond(s4) {
        margin-top: 0;
    }


    @include respond(m2) {
        justify-content: center;
        margin-top: 10px;
    }
}

.see-all {
    font: normal 18px $font-second;
    padding-right: 52px;
    position: relative;
    margin-right: 25px;

    &:before {
        @include before;

        height: 39px;
        width: 39px;
        border: 1px solid $color-primary;
        top: -6px;
        right: 0;
        box-sizing: border-box;

        @include transition;
    }

    span {
        position: absolute;
        top: 0px;
        right: 15px;
        transform: rotate(180deg);

        &:before {
            content: "\e900";
            font-family: icomoon;
            color: $color-primary;
            font-size: 13px;

            @include transition;
        }
    }

    &:hover {
        &:before {
            border-color: #b2c7c7;
        }

        span {
            &:before {
                color: #b2c7c7;
            }
        }
    }

    &.no-arrow {
        color: #6e6e6e;
        font-size: 16px;

        @include respond(m2) {
            margin-right: 0;
            padding-right: 0;
        }


        &:hover {
            color: $color-blue;
        }

        &:before,
        &:after {
            display: none;
        }
    }
}

.b-news__slider {
    padding: 28px 0 0;

    @include flex;

    justify-content: center;

    @include respond(s3) {
        padding-left: 50px;
        padding-right: 50px;
    }


    @include respond(m2) {
        max-width: 600px;
        margin-right: auto;
        margin-left: auto;
    }
}

.b-news__item {
    width: 362px;
    text-align: left;
    margin: 0 75px;

    @include respond(s1) {
        margin: 0 20px;
    }


    @include respond(s3) {
        margin: 0 auto;
    }


    @include respond(m3) {
        width: auto;
        max-width: 300px;
        padding-left: 5px;
        padding-right: 5px;
    }


    .embed-responsive {
        margin-bottom: 29px;
    }

    h4 {
        padding-bottom: 17px;
    }

    p {
        font: 500 12px / 200% $font-third;
        max-width: 297px;
    }
}

.b-news__img {
    // height: 240px;
    height: 0;
    position: relative;
    overflow: hidden;
    margin-bottom: 29px;
    padding-top: 56.25%;

    @include respond(m3) {
        // height: auto;
        margin-bottom: 15px;
    }


    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
    }

    // .embed-responsive{
    //   @include respond(m3){
    //     height: 193px;
    //   }
    //   @include respond(m4){
    //     height: 100%;
    //   }
    // }
}

.b-news__date {
    @include display_inline_block;

    background: #b2c7c7;
    color: #242424;
    font: normal 14px $font-second;
    padding: 11px 15px 10px;
    position: relative;
    margin-top: -52px;
    left: -1px;

    @include respond(m3) {
        margin-top: -30px;
    }
}

.video {
    width: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    height: 100%;

    // max-height: 310px;

    img {
        max-width: 100%;
    }
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.video-block::before,
.video-block__play {
    position: absolute;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0;
}

.video-block__play {
    margin: auto;
    background: url("../img/play.png") no-repeat center;
    cursor: pointer;
    position: absolute;
    z-index: 3;
}

.b-sponsors {
    background: $color-primary;
    padding-bottom: 52px;

    @include respond(m3) {
        padding-bottom: 33px;
    }


    h2 {
        color: #385864;

        &:before,
        &:after {
            background: #b2c7c7;
        }
    }

    .link-box {
        margin-top: -12px;
        margin-bottom: -14px;
        max-width: 100%;
        padding-right: 0;
        margin-right: 58px;

        @include respond(s4) {
            margin-bottom: 15px;
        }


        @include respond(m2) {
            margin-right: 0;
        }
    }
}

.b-sponsors__single {
    display: flex;
    flex-wrap: wrap;

    &--wrapper {
        background-color: #fff;
    }

    div {
        padding: 3rem;
        flex: 0 0 33.3%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
        }
    }

    @include respond(m2) {
        div {
            flex: 0 0 50%;
            padding: 1.5rem;
        }
    }
}

.b-sponsors__slider {
    padding: 0 140px;

    @include respond(m2) {
        max-width: 600px;
        margin: 0 auto;
    }


    @include respond(m3) {
        padding: 0 50px;
    }


    img {
        margin: 0 auto;
        max-width: 100%;
    }

    .slick-track {
        @include flex;

        align-items: center;

        div {
            @include flex;

            height: 100%;
        }
    }

    .slick-slide {
        justify-content: center;
    }
}

.b-about {
    background: #385864;
    padding-top: 67px;
    padding-bottom: 52px;

    @include respond(m3) {
        padding: 33px 0 50px;
    }


    .b-row {
        padding-top: 57px;

        @include respond(s3) {
            @include flex-col;
        }


        @include respond(m1) {
            padding-top: 20px;
        }


        @include respond(m3) {
            padding-top: 0;
        }
    }

    h2 {
        &:before,
        &:after {
            background: #242424;
        }
    }
}

.b-about__left {
    text-align: left;
    width: 52%;

    @include respond(s1) {
        width: 50%;
        padding-right: 30px;
    }


    @include respond(s3) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 40px;

        @include flex-col;
    }


    p {
        padding-bottom: 22px;
    }

    h3 {
        font: normal 19px $font-second;
        text-transform: none;
        color: $color-primary;
    }

    span {
        display: block;
        color: #b2c7c7;
        padding: 0 0 0 56px;
        position: relative;
        top: -2px;

        @include respond(s3) {
            margin-left: auto;
            padding-left: 0;
        }
    }
}

.b-about__right {
    padding: 2px 0 0;
    position: relative;
    right: -126px;

    @include respond(s1) {
        right: 0;
        width: 718px;
        margin-left: auto;
        padding-right: 50px;
        padding-left: 50px;
    }


    @include respond(s3) {
        width: 100%;
        padding: 0;
    }
}

.b-about__slider {
    width: 618px;

    @include respond(s1) {
        width: 100%;
    }


    @include respond(s3) {
        width: auto;
        max-width: 618px;
        margin: 0 auto;
    }


    @include respond(m3) {
        max-width: 380px;
    }


    @include respond(m4) {
        max-width: 260px;
    }


    @include respond(m5) {
        max-width: 240px;
    }


    img {
        max-width: 100%;
    }
}

.b-about__title {
    background: #b2c7c7;
    color: #242424;
    padding: 13px 25px 12px;
    letter-spacing: -0.01em;
    text-align: left;
    font: italic 300 14px $font-primary;
}

.signature {
    position: relative;
    left: -5px;

    @include respond(s3) {
        left: 0;
        margin-left: auto;
        display: block;
    }
}

.b-visit {
    background: url("../img/shop_bg.jpg") no-repeat top center;
    min-height: 445px;

    @include flex;

    align-items: center;

    @include respond(m3) {
        min-height: 300px;
    }


    .b-wrap {
        @include flex-col;

        justify-content: left;
        align-items: flex-start;
        text-align: left;

        @include respond(m1) {
            position: relative;
            left: 20px;
        }
    }

    h2 {
        font: normal 51px / 120% $font-second;
        padding-bottom: 20px;

        @include respond(m1) {
            font-size: 45px;
        }


        @include respond(m3) {
            font-size: 38px;
        }


        @include respond(m5) {
            font-size: 34px;
        }


        &:before,
        &:after {
            display: none;
        }

        span {
            display: block;
            position: relative;
            left: -5px;
            font: bold 36px / 120% $font-third;
            padding-bottom: 25px;

            @include respond(m3) {
                font-size: 26px;
            }


            @include respond(m5) {
                font-size: 22px;
            }
        }
    }

    .btn {
        position: relative;
        left: -9px;
        padding: 0 20px;
    }
}

.b-call {
    background: #385864;
    padding: 22px 0 10px;

    .b-wrap {
        @include flex;

        @include respond(m1) {
            @include flex-col;

            justify-content: center;
        }
    }

    p {
        font: normal 44px / 120% $font-second;
        text-transform: uppercase;
        padding-bottom: 0;

        @include respond(s5) {
            font-size: 35px;
        }


        @include respond(m1) {
            text-align: center;
            font-size: 28px;
        }


        @include respond(m3) {
            font-size: 18px;
        }
    }

    span {
        display: block;
        font: normal 34px / 120% $font-sixth;
        margin-left: auto;
        padding-left: 20px;
        padding-top: 5px;
        padding-right: 18px;

        @include respond(s5) {
            font-size: 25px;
        }


        @include respond(m1) {
            margin-right: auto;
            font-size: 18px;
        }


        @include respond(m3) {
            font-size: 14px;
        }
    }
}

.b-content {
    padding-top: 69px;
    padding-bottom: 0;

    @include respond(m3) {
        padding-top: 33px;
    }


    .b-wrap {
        @include flex;

        @include respond(s2) {
            @include display_table;
        }


        @include respond(m3) {
            @include flex-col;
        }
    }
}

.b-middle {
    width: 1086px;
    margin-left: auto;
    margin-right: 6px;

    @include respond(s2) {
        width: auto;
        margin-right: 0;
        margin-left: 320px;
    }


    @include respond(s4) {
        margin-left: 240px;
    }


    @include respond(m3) {
        margin-left: 0;
        width: 100%;
    }


    .b-products__list {
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 0;
    }

    .b-products__item,
    .b-collections__item {
        padding-bottom: 57px;

        @include respond(s2) {
            width: 31.333%;
            margin-left: 1%;
            margin-right: 1%;
        }


        @include respond(m2) {
            width: 48%;
            margin-right: 0;
            margin-left: 2%;
        }


        @include respond(m3) {
            padding-bottom: 30px;
            margin-left: 1%;
            margin-right: 1%;
        }


        @include respond(m7) {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }

    .b-products__img {
        @include respond(s2) {
            height: auto;
        }
    }
}

.b-content_services {
    padding-top: 13px;
    padding-bottom: 47px;

    @include respond(m3) {
        padding-bottom: 20px;
    }


    .b-wrap {
        @include flex-col;
    }
}

.b-service {
    margin-bottom: 32px;
    position: relative;
    text-align: left;
    width: 100%;

    &:before {
        @include before;

        background: url("../img/shadow_big.png") no-repeat bottom center;
        height: 158px;
        width: 1413px;
        left: 50%;
        margin-left: -706px;
        bottom: -79px;
        z-index: 100;

        @include respond(m3) {
            display: none;
        }
    }

    img {
        float: left;

        @media (min-width: 1051px) {
            width: 30%;
        }

        @include respond(m1) {
            max-width: 400px;
        }


        @include respond(m2) {
            max-width: 340px;
            margin-top: 25px;
            margin-left: 25px;
            margin-bottom: 25px;
        }


        @include respond(m3) {
            margin: 0 0 30px;
            float: none;
        }
    }

    .b-service__info {
        margin-left: 549px;
        padding: 50px 50px 28px 0;

        @media (min-width: 1051px) {
            margin-left: 35%;
        }

        @include respond(s5) {
            padding: 30px 30px 25px 0;
            margin-left: 520px;
        }


        @include respond(m1) {
            margin-left: 430px;
        }


        @include respond(m2) {
            margin-left: 385px;
        }


        @include respond(m3) {
            margin-left: 0;
            padding: 0;
        }


        h4 {
            font: normal 31px $font-second;
            text-transform: uppercase;
            padding-bottom: 6px;

            @include respond(m1) {
                font-size: 27px;
            }
        }

        p {
            max-width: 777px;
            padding-bottom: 34px;

            br {
                @include respond(s1) {
                    display: none;
                }
            }
        }

        a {
            color: #b2c7c7;

            @include display_inline_block;

            font: bold 20px / 100% $font-third;
            border-bottom: 1px solid #b2c7c7;

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }
}

.b-service__content {
    background: #1d1d1d;
    position: relative;
    z-index: 200;

    @include display_table;

    @include respond(m3) {
        @include flex-col;

        padding: 15px 15px;
    }
}

.b-graphs {
    padding-top: 48px;
    padding-bottom: 105px;

    @include respond(m1) {
        padding-bottom: 50px;
    }


    @include respond(m3) {
        padding-top: 33px;
        padding-bottom: 30px;
    }


    p {
        max-width: 936px;
        margin: 0 auto;
        text-align: left;
    }

    h2 {
        color: #b2c7c7;
        font-size: 37px;
        max-width: 1037px;
        margin: 0 auto;
        line-height: 90%;
        padding-bottom: 48px;

        @include respond(m1) {
            font-size: 34px;
        }


        @include respond(m3) {
            font-size: 22px;
            padding-bottom: 20px;
        }


        &:before,
        &:after {
            display: none;
        }
    }
}

.b-graph {
    margin-bottom: 32px;

    img {
        max-width: 100%;
        margin: 0 auto;
    }
}

.b-customers-sort {
    background: #385864;
    position: relative;
    z-index: 902;
    padding-top: 23px;
    padding-bottom: 41px;

    @include respond(m3) {
        padding-bottom: 20px;
    }


    .b-row {
        justify-content: center;

        @include respond(m3) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    p {
        padding-bottom: 52px;
        max-width: 1214px;
        margin: 0 auto;
        font: normal 19px / 170% $font-sixth;

        @include respond(s3) {
            padding-right: 20px;
            padding-left: 20px;
        }


        @include respond(m3) {
            padding-bottom: 20px;
            font-size: 16px;
        }
    }
}

.radio,
.radio2 {
    position: absolute;
    opacity: 0;
}

.radio + label,
.radio2 + label,
.b-tabs li {
    font: normal 25px $font-second;
    text-transform: uppercase;
    border: 3px solid #2b3738;
    border-radius: 5px;
    box-sizing: border-box;
    min-height: 83px;
    align-items: center;
    padding: 10px 48px;
    margin: 0 29px;
    position: relative;

    @include flex;

    text-align: center;
    cursor: pointer;

    @include transition;

    @include respond(s3) {
        font-size: 20px;
        min-height: 70px;
        padding: 6px 15px 4px;
    }


    @include respond(m2) {
        margin: 0 5px;
        padding: 4px 10px 3px;
        font-size: 18px;
    }


    @include respond(m6) {
        font-size: 12px;
        padding: 2px 6px;
        margin: 0 2px;
        min-height: 60px;
    }


    &:hover {
        background: #2b3738;
    }
}

.radio:checked + label,
.radio2:checked + label,
.b-tabs li.current {
    background: #2b3738;
    cursor: default;

    &:after {
        @include before;

        border-top: 80px solid #2b3738;
        border-left: 26px solid transparent;
        border-right: 26px solid transparent;
        bottom: -80px;
        left: 50%;
        margin-left: -26px;
        z-index: 902;

        @include respond(m6) {
            border-top-width: 50px;
            border-left-width: 15px;
            border-right-width: 15px;
            bottom: -50px;
            margin-left: -15px;
        }
    }
}

.b-customer {
    text-align: left;
    padding-bottom: 64px;

    @include respond(m3) {
        padding-bottom: 33px;
    }


    .b-row {
        padding-top: 11px;

        @include respond(m1) {
            @include flex-col;
        }
    }
}

.link-back {
    font: normal 10px / 100% $font-second;
    text-transform: uppercase;
    position: relative;
    padding: 0 0 0 15px;
    top: -21px;
    left: -45px;
    color: #6e6e6e;

    @include respond(s2) {
        left: 0;
    }


    &:before {
        content: "\e900";
        font-family: icomoon;
        color: #b2c7c7;
        font-size: 16px;
        position: absolute;
        top: 2px;
        left: 0;
    }
}

.b-customer__name {
    width: 424px;
    min-width: 424px;
    border-top: 3px solid #b2c7c7;
    border-bottom: 3px solid #b2c7c7;
    margin-bottom: auto;
    padding: 20px 0 8px;
    margin-top: 29px;

    @include respond(m1) {
        margin-top: 0;
        width: auto;
        margin-bottom: 30px;
        max-width: 450px;
        min-width: auto;
    }


    @include respond(m3) {
        padding: 10px 0;
    }


    strong {
        display: block;
        color: #b2c7c7;
        font: normal 53px / 100% $font-second;
        text-transform: uppercase;
        padding-bottom: 2px;

        @include respond(m3) {
            font-size: 25px;
        }
    }

    span {
        display: block;
        font: bold italic 33px $font-seventh;

        @include respond(m3) {
            font-size: 20px;
        }
    }
}

.b-customer__info {
    width: 913px;
    margin-left: auto;
    position: relative;
    left: 35px;

    @include respond(s2) {
        width: auto;
        left: 0;
        padding-left: 35px;
    }


    @include respond(m1) {
        width: 100%;
        padding-left: 0;
    }


    p {
        padding-bottom: 25px;

        i {
            font: 600 italic 14px $font-primary;
        }
    }
}

.b-customer__photo {
    margin: 31px 85px 0;

    @include respond(s0) {
        margin-left: 0;
        margin-right: 0;
    }


    @include respond(m1) {
        margin-top: 20px;
    }


    @include respond(m3) {
        margin-top: 0;
    }


    img {
        position: relative;
        z-index: 100;
    }

    a {
        &:before {
            @include before;

            background: #405763;
            height: 77px;
            width: 77px;
            top: 50%;
            left: 50%;
            margin-top: -39px;
            margin-left: -39px;

            @include transition;

            opacity: 0;
            z-index: 200;
        }

        &:after {
            content: "\e905";
            color: #fff;
            font-family: icomoon;
            font-size: 26px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -12px;
            margin-left: -12px;

            @include transition;

            opacity: 0;
            z-index: 200;
        }

        &:hover {
            &:before,
            &:after {
                opacity: 1;
            }
        }
    }
}

.links-row {
    @include flex;

    justify-content: center;
    padding: 38px 0 0;

    @include respond(m4) {
        flex-wrap: wrap;
    }


    a {
        color: #b2c7c7;
        font: normal 19px / 100% $font-second;
        position: relative;
        margin: 0 85px;
        text-transform: uppercase;

        @include respond(m3) {
            margin: 0 15px;
        }


        @include respond(m6) {
            font-size: 16px;
            white-space: nowrap;
        }


        &:hover {
            color: $color-primary;
        }

        &.link-prev {
            padding-left: 32px;

            @include respond(m6) {
                padding-left: 20px;
            }


            @include respond(m4) {
                margin-left: 20px;
                margin-right: auto;
            }


            &:before {
                content: "\e900";
                font-family: icomoon;
                color: #b2c7c7;
                font-size: 36px;
                position: absolute;
                top: -1px;
                left: 0;

                @include transition;

                @include respond(m6) {
                    font-size: 26px;
                    top: 0;
                }
            }

            &:hover {
                &:before {
                    color: $color-primary;
                }
            }
        }

        &.link-next {
            padding-right: 32px;

            @include respond(m6) {
                padding-right: 20px;
            }


            @include respond(m4) {
                margin-top: 50px;
            }


            @include respond(m4) {
                margin-left: auto;
                margin-right: 20px;
            }


            &:before {
                content: "\e900";
                font-family: icomoon;
                color: #b2c7c7;
                font-size: 36px;
                position: absolute;
                top: -1px;
                right: 0;
                transform: rotate(180deg);

                @include transition;

                @include respond(m6) {
                    font-size: 26px;
                    top: 0;
                }
            }

            &:hover {
                &:before {
                    color: $color-primary;
                }
            }
        }
    }
}

.b-docs {
    padding-top: 59px;
    padding-bottom: 39px;

    @include respond(m3) {
        padding: 33px 0;
    }


    .b-wrap {
        max-width: 100%;
        flex-wrap: wrap;

        @include flex;

        justify-content: center;
    }
}

.b-docs__item {
    display: block;
    text-align: center;
    padding: 28px 15px 11px;
    border: 1px solid #2b3738;
    border-radius: 5px;
    background: rgba(56, 88, 100, 0.57);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
    width: 392px;
    box-sizing: border-box;
    margin: 0 43px 60px;

    @include respond(s2) {
        width: 31.333%;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 2%;
    }


    @include respond(m1) {
        width: 48%;
    }


    @include respond(m3) {
        width: 100%;
        margin: 0 0 20px;
    }


    strong {
        display: block;
        font: normal 25px / 100% $font-second;
        position: relative;
        padding-bottom: 24px;
        text-transform: uppercase;

        @include transition;

        &:after {
            @include before;

            background: #2b3738;
            height: 3px;
            width: 70px;
            left: 50%;
            margin-left: -35px;
            bottom: 12px;
        }
    }

    span {
        font: normal 17px $font-sixth;
        display: block;
        max-width: 300px;
        margin: 0 auto;
        padding-bottom: 15px;

        @include transition;
    }

    svg {
        path {
            @include transition;
        }
    }

    &:hover {
        background: #b2c7c7;

        strong {
            color: #385864;
        }

        span {
            color: rgba(38, 38, 38, 95);
        }

        svg {
            path {
                fill: #33464d;
            }
        }
    }
}

.b-sale {
    padding-top: 46px;
    padding-bottom: 46px;

    @include respond(m3) {
        padding: 33px 0;
    }


    .b-wrap {
        @include flex;

        justify-content: center;
        align-items: center;

        @include respond(m6) {
            @include flex-col;
        }
    }

    .b-docs__item {
        border-radius: 0 5px 5px 0;
        margin: auto 0;
        width: 606px;

        @include respond(m6) {
            width: 100%;
            border-radius: 5px;
        }


        span {
            max-width: 370px;
        }
    }

    img {
        max-width: 100%;

        @include respond(m3) {
            max-width: 250px;
        }


        @include respond(m6) {
            margin: 0 auto 20px;
        }


        @include respond(m5) {
            max-width: 100%;
        }
    }
}

.b-contact {
    padding-top: 61px;
    padding-bottom: 38px;

    @include respond(m3) {
        padding: 20px 0 30px;
    }


    .b-row {
        @include flex;

        justify-content: center;
        padding-bottom: 126px;

        @include respond(m1) {
            @include flex-col;

            padding-right: 20px;
            padding-left: 20px;
            padding-bottom: 30px;
        }
    }

    .b-wrap {
        @include flex;

        max-width: 1220px;

        @include respond(m3) {
            @include flex-col;
        }
    }
}

.b-contact__item {
    width: 405px;
    margin: 0 70px;
    position: relative;
    text-align: left;
    background: #d9d9d9;

    @include respond(s2) {
        margin: 0 20px;
    }


    @include respond(s4) {
        margin: 0 10px;
    }


    @include respond(m1) {
        width: 100%;
        margin: 0 auto 15px;
    }


    &:before {
        @include before;

        background: rgb(217, 217, 217);
        background: -moz-linear-gradient(left, rgba(217, 217, 217, 1) 0%, rgba(147, 147, 147, 1) 100%);
        background: -webkit-linear-gradient(left, rgba(217, 217, 217, 1) 0%, rgba(147, 147, 147, 1) 100%);
        background: linear-gradient(to right, rgba(217, 217, 217, 1) 0%, rgba(147, 147, 147, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9d9d9', endColorstr='#939393', GradientType=1);
        top: 0;
        right: 0;
        height: 100%;
        width: 100px;

        @include respond(m2) {
            width: 30%;
        }
    }

    strong {
        display: block;
        font: normal 25px / 120% $font-second;
        text-transform: uppercase;
        padding-bottom: 11px;
    }

    address {
        display: block;
        color: #242424;
        font: normal 16px / 160% $font-primary;
        max-width: 168px;

        @include respond(m2) {
            max-width: 300px;
        }
    }
}

.b-contact__icon {
    background: #618896;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100px;
    text-align: center;

    @include flex;

    justify-content: center;
    align-items: center;

    @include respond(m5) {
        width: 70px;
    }


    img {
        margin: auto;

        @include respond(m5) {
            transform: scale(0.7);
        }
    }
}

.b-contact__body {
    padding: 32px 20px 12px 30px;
    min-height: 162px;
    color: #232223;
    position: relative;
    margin-left: 100px;

    @include respond(s3) {
        padding-right: 15px;
        padding-left: 15px;
    }


    @include respond(m5) {
        margin-left: 70px;
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: auto;
    }
}

.b-contact__tel {
    color: #333333;
    display: block;
    font: bold italic 32px / 100% $font-ninth;
    white-space: nowrap;

    @include respond(m4) {
        font-size: 28px;
    }


    &:hover {
        color: $color-blue;
    }
}

.b-contact__hd,
.b-socials__hd {
    width: 454px;
    min-width: 454px;
    text-align: left;

    @include respond(m1) {
        min-width: auto;
        padding-right: 20px;
    }


    @include respond(m3) {
        width: 100%;
        padding-bottom: 20px;
        padding-right: 0;
    }


    h3 {
        color: $color-primary;
        font: normal 31px / 100% $font-second;
        text-transform: uppercase;
        border-top: 3px solid #b2c7c7;
        border-bottom: 3px solid #b2c7c7;

        @include display_inline_block;

        padding: 10px 1px 6px;
        margin-bottom: 10px;

        @include respond(m1) {
            font-size: 22px;
        }


        @include respond(m2) {
            font-size: 18px;
        }


        @include respond(m3) {
            margin: 0 auto 20px;
            text-align: center;
            display: block;
            max-width: 300px;
        }
    }

    p {
        @include respond(m3) {
            text-align: center;
        }
    }
}

.b-socials__hd {
    position: relative;
    left: -20px;

    @include respond(m1) {
        left: 0;
    }
}

.b-contact__form {
    width: 671px;
    margin-left: auto;
    text-align: left;
    padding-bottom: 145px;

    @include respond(m1) {
        padding-bottom: 80px;
    }


    @include respond(m3) {
        width: 100%;
        padding-bottom: 40px;
    }
}

.b-form {
    .btn {
        margin-top: 30px;
    }
}

.b-form__row {
    @include flex;

    @include respond(m4) {
        @include flex-col;
    }


    .b-input {
        width: 47.5%;

        @include respond(m1) {
            width: 49%;
        }


        @include respond(m4) {
            width: 100%;
        }


        &:last-child {
            margin-left: auto;
        }
    }
}

.b-form__row_file {
    @include respond(m4) {
        @include flex;

        flex-wrap: wrap;
    }
}

.file-preview {
    background: url("../img/file.png") no-repeat;
    height: 43px;
    width: 55px;
    margin: 0 10px 0 0;
    position: relative;
    top: 1px;

    @include image-retina("../img/file", "png", "55px", "43px");

    img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }

    &.has-img {
        background: none;
    }
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.file__label {
    background: rgba(247, 247, 248, 0.22);
    border: 1px solid rgba(147, 147, 147, 0.22);
    font: normal 16px $font-primary;
    height: 42px;
    line-height: 42px;
    text-transform: uppercase;
    padding: 0 10px;
    cursor: pointer;
    position: relative;

    @include transition;

    &:hover {
        background: rgba(97, 136, 150, 0.22);
    }
}

.file__txt {
    display: block;
    padding: 10px 37px 0 0;

    @include respond(m5) {
        width: 100%;
        padding-bottom: 15px;
    }
}

.b-contact__socials {
    width: 714px;
    margin-left: auto;
    text-align: center;

    @include flex;

    justify-content: space-between;
    position: relative;
    right: -50px;
    top: -16px;

    @include respond(s3) {
        right: 0;
    }


    @include respond(m2) {
        flex-wrap: wrap;
    }


    @include respond(m1) {
        right: 0;
    }


    @include respond(m3) {
        width: 100%;
        top: 0;
    }


    .social-links__item {
        width: auto;
        margin: 0 10px;

        @include respond(m2) {
            width: 50%;
            margin: 0 auto 40px;
        }


        @include respond(m5) {
            width: 100%;
        }
    }
}

.b-news-page {
    padding-top: 47px;
    padding-bottom: 40px;

    @include respond(m3) {
        padding: 33px 0;
    }


    .b-row {
        @include respond(m3) {
            @include flex-col;
        }
    }
}

.b-news-page__content {
    width: 980px;
    text-align: left;

    @include respond(m1) {
        width: 70%;
    }


    @include respond(m3) {
        order: 1;
        width: 100%;
    }
}

.b-news-page__item {
    padding: 0 0 123px 0;

    @include respond(m1) {
        padding-bottom: 80px;
    }


    @include respond(m3) {
        padding-bottom: 50px;
    }


    &:last-child {
        padding-bottom: 0;
    }

    h3 {
        color: #b2c7c7;
        font: normal 37px / 90% $font-second;
        text-transform: uppercase;
        border-bottom: 3px solid #b2c7c7;
        padding: 15px 0 10px;

        @include respond(m1) {
            font-size: 30px;
            line-height: 100%;
        }


        @include respond(m3) {
            font-size: 20px;
        }


        a {
            color: #b2c7c7;

            &:hover {
                color: $color-primary;
            }
        }
    }

    img {
        max-width: 100%;
        margin-bottom: 24px;
    }
}

.b-news-page__date {
    border-bottom: 3px solid #b2c7c7;

    span {
        @include display_inline_block;

        background: #b2c7c7;
        color: #242424;
        font: normal 23px / 100% $font-second;
        padding: 9px 27px 7px;
        letter-spacing: 0.03em;

        @include respond(m3) {
            font-size: 18px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.b-news-page__btn {
    @include flex;

    padding: 18px 0 0;

    @include respond(m3) {
        @include flex-col;
    }


    .btn {
        margin-right: auto;
    }
}

.b-share {
    margin-left: auto;

    @include flex;

    justify-content: flex-end;
    padding-top: 12px;

    @include respond(m3) {
        margin-top: 10px;
    }


    .b-socials {
        margin-right: 18px;
    }

    span {
        color: #798889;
        display: block;
        padding: 11px 5px 0 0;
        font: bold italic 17px / 100% $font-tenth;
    }
}

.pagination {
    @include flex;

    padding: 3px 0 10px;
    position: relative;
    left: -7px;

    @include respond(m3) {
        padding: 20px 0;
        left: 0;
        justify-content: center;
    }


    a {
        display: block;
        height: 60px;
        width: 60px;
        background: #151515;
        text-align: center;
        color: $color-primary;
        font: bold 21px / 60px $font-seventh;
        margin: 0 6px 0 7px;
        box-sizing: border-box;
        position: relative;

        @include respond(m2) {
            height: 45px;
            width: 45px;
            font-size: 18px;
            line-height: 45px;
        }


        &:hover,
        &.active {
            background: #b2c7c7;
            color: $color-primary;
        }

        &.active {
            cursor: default;
        }

        &:first-child,
        &:last-child {
            background: none;
        }

        i {
            position: relative;
            top: 7px;
            color: #b2c7c7;
            font-size: 45px;
            line-height: 100%;

            @include transition;

            @include respond(m2) {
                top: 5px;
                font-size: 30px;
            }
        }

        &:first-child {
            left: -6px;
        }

        &:last-child {
            right: -6px;
        }

        &.page-arrow:hover {
            background: none;

            i {
                color: $color-blue;
            }
        }

        &:nth-child(n + 4):nth-child(-n + 9) {
            @include respond(m3) {
                display: none;
            }
        }
    }

    span {
        display: none;

        @include respond(m3) {
            display: block;
            font: bold 21px / 100% $font-seventh;
            position: relative;
            top: 13px;
            padding: 0 10px;
        }
    }
}

.b-news-page__list {
    padding-bottom: 75px;
}

.b-gallery {
    padding-top: 23px;
    padding-bottom: 56px;

    @include respond(m3) {
        padding-bottom: 33px;
    }


    h4 {
        color: #b2c7c7;
        font: normal 25px / 100% $font-second;
        border-bottom: 3px solid #b2c7c7;
        border-top: 3px solid #b2c7c7;

        @include display_inline_block;

        padding: 5px 8px;
    }
}
.b-gallery__wrap {
    opacity: 1;
    @include transition;
    &.g-init {
        opacity: 0;
    }
}

.b-tabs {
    @include flex;

    justify-content: center;
    padding-bottom: 70px;

    li {
        padding-left: 101px;
        padding-right: 101px;

        @include respond(m2) {
            padding: 4px 30px 3px;
        }


        @include respond(m3) {
            padding-left: 15px;
            padding-right: 15px;
            min-height: 50px;
        }


        @include respond(m6) {
            font-size: 18px;
        }


        &.current {
            cursor: default;

            &:after {
                border-top-width: 62px;
                border-left-width: 26px;
                border-right-width: 26px;
                margin-left: -26px;
                bottom: -62px;

                @include respond(m3) {
                    border-top-width: 48px;
                    bottom: -48px;
                    border-left-width: 18px;
                    border-right-width: 18px;
                    margin-left: -18px;
                }
            }
        }
    }
}

.box {
    display: none;
    text-align: center;

    &.visible {
        display: block;
    }
}

.b-row_sort {
    padding-bottom: 22px;
    text-align: center;
    flex-wrap: wrap;
    max-width: 1608px;
    margin: 0 auto;
    padding-top: 32px;

    @include respond(m3) {
        padding-bottom: 0;
    }
}

.radio2 + label {
    background: none;
    padding: 0;
    color: $color-primary;
    font-size: 25px;
    margin: 0 19px 17px;
    border: 0;
    min-height: auto;

    @include display_inline_block;

    @include respond(s1) {
        font-size: 20px;
    }


    @include respond(m2) {
        font-size: 16px;
    }


    @include respond(m3) {
        font-size: 13px;
        margin: 0 14px 10px;
    }


    @include respond(m4) {
        display: block;
    }


    &:before {
        @include before;

        top: 2px;
        right: -23px;
        background: #b2c7c7;
        height: 32px;
        width: 3px;

        @include respond(s1) {
            height: 24px;
        }


        @include respond(m2) {
            height: 20px;
        }


        @include respond(m3) {
            display: none;
        }
    }

    &:nth-child(10),
    &:last-child {
        &:before {
            display: none;

            @include respond(s1) {
                display: block;
            }


            @include respond(m3) {
                display: none;
            }
        }
    }

    &:last-child {
        &:before {
            @include respond(s1) {
                display: none;
            }
        }
    }

    &:hover {
        background: none;
        color: #b2c7c7;
    }
}

.radio2:checked + label {
    background: none;
    color: #b2c7c7;

    &:after {
        display: none;
    }
}

.b-gallery__wrap {
    position: relative;
    left: -11px;
    margin-right: -22px;

    @include respond(s1) {
        left: 0;
        margin-right: 0;
    }
}

.b-videos {
    @include flex;

    flex-wrap: wrap;
    justify-content: center;

    @include respond(m1) {
        @include flex-col;
    }


    // .embed-responsive{
    //   margin: 0 0 13px;
    //   height: 364px;
    //   max-height: 364px;

    //   @include respond(s1){
    //     height: 300px;
    //     max-height: 300px;
    //   }
    //   @include respond(s4){
    //     height: 250px;
    //     max-height: 250px;
    //   }
    //   @include respond(m1){
    //     height: 100%;
    //     max-height: 100%;
    //     max-width: 930px;
    //   }

    //   img{
    //     @include respond(m1){
    //       width: 100%;
    //     }
    //   }
    // }
}

.b-videos__item {
    width: 657px;
    margin: 0 46px 20px;

    @include flex-col;

    @include respond(s1) {
        width: 541px;
    }


    @include respond(s3) {
        margin: 0 20px 20px;
    }


    @include respond(s4) {
        width: 451px;
    }


    @include respond(m1) {
        width: 100%;
        margin: 0 auto 30px;
        max-width: 657px;
    }
}

.b-videos__title {
    background: #2b3738;
    color: #b2c7c7;
    font: 300 italic 14px $font-primary;
    padding: 13px 24px;
    text-align: left;
}

.product-single {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    flex-wrap: wrap;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:before,
        &:after {
            content: none;
        }
    }

    &--title {
        flex: 1 1 100%;
        margin-bottom: 1.5rem;
    }

    &--img {
        flex: 0 0 35%;
        padding-right: 5%;
        a {
            cursor: zoom-in;
        }

        img {
            max-width: 100%;
        }
        h3 {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
        &-main {
            object-fit: contain;
            width: 100%;
            height: 300px;
            object-position: left top;
        }
        &-container {
            a {
                display: inline-block;
            }
        }

        .animating {
            opacity: 0;
            transition: 500;
        }
        &-thumbs {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(3, 1fr);
            img {
                border: 3px solid transparent;
                border-radius: 7px;
                transition: border-color 0.4s ease-in-out;
                &.active {
                    border-color: #86c6c7;
                }
            }
        }
    }


    &--info {
        flex: 0 0 65%;
        font-size: 18px;

        .product-single--line + div,
        .product-single--line + p {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }

        ul,
        ol {
            padding-left: 1rem;
        }

        ul,
        ol,
        p {
            margin-bottom: 1rem;
        }

        ul {
            li {
                list-style-type: disc;

                ul {
                    font-size: 14px;
                    margin-bottom: 0.5rem;

                    li {
                        list-style-type: circle;
                    }
                }
            }
        }
    }

    &--line {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        @media screen and(min-width: 1051px) {
            flex-wrap: wrap;
        }
    }

    @include respond(m3) {
        padding-left: 5%;
        padding-right: 5%;

        h1 {
            font-size: 28px;
        }

        &--line {
            flex-wrap: wrap;
        }

        &--img {
            flex: 0 0 100%;
            padding-right: 0;
        }

        &--info {
            flex: 0 0 100%;
        }
    }
}

.spl {
    &--variants {
        // align-self: stretch;
        display: inline-flex;
        align-items: center;
        color: white;
        font-weight: 700;
        font-size: 20px;
        border-bottom: 2px solid white;
        background-color: $color-primary;
        width: 30%;
        position: relative;
        
        @media screen and(min-width: 1051px) {
            flex-basis: 100%;
            margin-bottom: 1rem;
            padding: 15px;
        }
        

        // flex-grow: 2;

        &.hidden {
            display: none;
        }

        select {
            width: 100%;
            height: 100%;
            z-index: 20;
            appearance: none;
            border: none;
            font: inherit;
            font-weight: inherit;
            // background-color: transparent;
            color: inherit;
            padding-right: 15px;
            background-color: $color-primary;
            color: $color-blue;
            @media screen and(max-width: 1050px) {
                font-size: .9rem;
                padding-left: 5px;
            }
        }

        &:after {
            font-family: $font-awesome;
            font-weight: 900;
            content: "\f078";
            position: absolute;
            display: block;
            // right: 5px;
            top: 52%;
            transform: translateY(-50%);
            
            text-align: center;
            width: 20px;
            z-index: 25;
            right: 5px;
            background-color: $color-primary;
            color: $color-blue;
            pointer-events: none;
            @media screen and(min-width: 1051px) {
                right: 20px;
            }
        }
    }

    &--variant {
        display: block;
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        background-color: #385864;
    }

    &--price {
        font-size: 2rem;
        margin-left: 15px;
    }

    &--qty {
        display: inline-flex;
        align-items: center;
        align-self: stretch;
        margin-left: 15px;

        i {
            font-size: 2rem;
        }

        input {
            margin-left: 5px;
            margin-right: 5px;
            font-size: 20px;
            font-family: $font-primary;
            color: #385864;
            font-weight: 700;
            max-width: 55px;
            text-align: center;
            height: 100%;
        }
    }

    &--add-to-cart {
        margin-left: 15px;

        i {
            padding-right: 5px;
        }
    }

    @include respond(m3) {
        &--variants {
            flex: 0 0 100%;
            width: 100%;
            margin-bottom: 1rem;
        }

        &--price {
            font-size: 1.5rem;
        }

        &--price,
        &--qty {
            flex: 0 0 50%;
            margin-left: 0;
        }

        &--qty {
            justify-content: flex-end;
        }

        &--add-to-cart {
            margin-left: 0;
            margin-top: 1rem;
        }
    }
}

.cart-container {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;

    &.invisible {
        max-height: 0px !important;
    }

    span.note {
        font-size: 1.25rem;
        font-weight: 700;
        padding-right: 0.5rem;
    }

    .cart {
        padding-top: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        max-width: 990px;
        margin-left: auto;
        margin-right: auto;

        .product {
            display: flex;
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid #fff;

            & > * {
                padding-left: 5px;
                padding-right: 5px;
            }

            .product-title--section {
                flex: 0 0 290px;
            }

            .product-right--section {
                flex: 1 1 auto;
                text-align: right;
                font-size: 1.5rem;
            }

            .product-remove {
                align-self: center;
                font-size: 1.5rem;
                margin-right: 1rem;
                color: rgba(255, 255, 255, 0.5);

                &:hover {
                    color: rgba(255, 255, 255, 1);
                }
            }

            .product-title {
                font-weight: 700;
                font-size: 1.1rem;
                margin-bottom: 0.5rem;
            }

            .variant-price {
                font-weight: 700;
                margin-bottom: 0.5rem;
            }

            .variant-quantity {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                i {
                    cursor: pointer;
                    position: relative;
                    z-index: 10;
                }

                input {
                    appearance: none;
                    border: none;
                    font-size: inherit;
                    width: 3rem;
                    text-align: center;
                    background-color: transparent;
                    color: inherit;
                    font-weight: 700;
                }
            }

            .variant-image {
                width: 150px;
                height: auto;
            }
        }
    }

    .cart-total,
    .buy-button--container {
        max-width: 990px;
        margin-left: auto;
        margin-right: auto;
        text-align: right;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 2rem;
        font-weight: 700;

        &.hidden {
            display: none;
        }
    }

    .cart-total {
        margin-bottom: 1rem;
    }

    .buy-button--container {
        .btn {
            i {
                padding-right: 0.5rem;
            }
        }
    }

    .cart-status {
        text-align: center;
        font-size: 1.25rem;
        font-weight: 700;
        padding-bottom: 2rem;
    }

    @media (min-width: 768px) {
        position: absolute;
        top: 58px;
        right: 84px;
        background-color: #385864;
        width: 720px;
        z-index: 2000;
    }

    @include respond(m3) {
        right: auto;

        span.note {
            font-size: 1rem;
        }

        .cart {
            .product {
                flex-wrap: wrap;

                .product-title--section {
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: calc(70% - 60px);
                }

                .product-right--section {
                    margin-top: 1rem;
                }

                .variant-image {
                    flex: 0 0 30%;
                    max-width: 30%;
                }

                .variant-price {
                    margin-bottom: 0;
                }

                .variant-quantity {
                    i {
                        padding-left: 3px;
                        padding-right: 3px;
                    }
                }
            }
        }
    }


    @include respond(s0) {
        right: 20px;
    }
}
