/*!; slick.css ;*/
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
       box-sizing: border-box;

  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-track,
  .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:focus{
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-track {
  position: relative;
  top: 0;
  display: block;
  margin:0 auto;

  &:before,
  &:after{
    display: table;
    content: '';
  }
  &:after{
    clear: both;
  }
}

.slick-loading{
  .slick-track {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  outline:none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
}

.slick-prev.slick-disabled, .slick-next.slick-disabled{
  opacity: .3;
  cursor: default;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -23px;
  cursor: pointer;
  transition: all .3s ease;
  height: 47px;
  width: 27px;
  z-index: 800;
  background: none;
  padding: 0;

  &.slick-prev{
    left: 28px;

    @include respond(m3){
      left: 10px;
    }
  }

  &.slick-next{
    right: 28px;

    @include respond(m3){
      right: 10px;
    }
  }

  svg{
    @include respond(m3){
      width: 16px;
    }
  }

  stop,
  path{
    @include transition;
  }

  &:not(.slick-disabled):hover{
    stop{
      stop-color: $color-primary;
    }
    path{
      fill: $color-primary;
    }
  }
}

.b-customers__slider{
  .slick-arrow{
    &.slick-prev{
      left: -117px;

      @include respond(s0){
        left: -28px;
      }
      @include respond(s2){
        left: 28px;
      }
      @include respond(m3){
        left: 10px;
      }
    }
    &.slick-next{
      right: -117px;

      @include respond(s0){
        right: -28px;
      }
      @include respond(s2){
        right: 28px;
      }
      @include respond(m3){
        right: 10px;
      }
    }
  }
}

.b-sponsors__slider{
  .slick-arrow{
    &.slick-prev{
      left: 39px;

      @include respond(m3){
        left: 10px;
      }
    }
    &.slick-next{
      right: 39px;

      @include respond(m3){
        right: 10px;
      }
    }

    &:not(.slick-disabled):hover{
      stop{
        stop-color: $color-blue;
      }
      path{
        fill: $color-blue;
      }
    }
  }
}

.b-about__slider{
  .slick-arrow{
    &.slick-prev{
      left: -53px;

      @include respond(m5){
        left: -30px;
      }
    }

    &.slick-next{
      right: -53px;

      @include respond(m5){
        right: -30px;
      }
    }
  }

  .slick-dots{
    bottom: -26px;
    left: 0;
    margin-left: 0;
    width: 100%;
  }
}

.slick-dots{
  position:absolute;
  bottom: 20px;
  left: 50%;
  width: 1412px;
  margin-left: -706px;
  box-sizing:border-box;
  z-index: 10;
  font-size: 0;
  padding: 0px;
  height: 11px;

  @include respond(s1){
    width: 100%;
    margin-left: 0;
    left: 0px;
    text-align: center;
  }

  li{
    @include display_inline_block;
    height: 11px;
    width: 11px;
    position:relative;
    text-indent:-9999px;
    margin: 0 7px 0 0 !important;
    padding: 0;

    @include respond(s2){
      margin: 0 3px !important;
    }

    button{
      text-indent:-9999px;
      background: #2B3738;
      height:11px;
      width:11px;
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0 auto;
      cursor:pointer;
      overflow:hidden;
      padding:0;
      @include transition;

      &:hover{
        background: #B2C7C7;
      }
    }

    &.slick-active{
      button{
        background: #B2C7C7;
        cursor: default;
      }
    }
  }
}

.default,
.small{
  .slick-dots{
    display: none !important;

    @include respond(m3){
      display: block !important;
    }
  }
}
