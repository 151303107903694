// colors
$color-primary: #ffffff;
$color-blue: #618896;
$color-light-blue: #B2C7C7;


// webfonts
$font-primary: 'Open Sans';
$font-second: 'Fugaz One';
$font-third: 'Changa';
$font-fourth: 'Electrolize';
$font-fifth: 'Raleway';
$font-sixth: 'Tomorrow';
$font-seventh: 'Lato';
$font-eighth: 'Montserrat';
$font-ninth: 'Asap';
$font-tenth: 'Merriweather';
$font-awesome: 'Font Awesome 5 Free';
$fontsize-base: 14px;


@mixin base-indent {
  margin: 0px;
  padding: 0px;
}

@mixin clear {
  clear:both;
  height:0px;
  font-size:0px;
}

@mixin img {
  display: block;
  max-width: 100%;
}

@mixin flex {
  display: flex;
  flex-direction: row;
}

@mixin flex-col{
  display: flex;
  flex-direction: column;
}

@mixin transition {
  transition: all .3s ease;
}

@mixin pos {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin img-fit{
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  max-height: 100%;
  object-fit: cover;
}

@mixin image-retina($file, $type, $width, $height) {

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
    & {
      background-image: url($file + '@2x.' + $type);
      background-position: 0 0;
      background-size: cover;
      -webkit-background-size: $width $height;
    }
  }
}

@mixin before{
  content: '';
  position: absolute;
}

@mixin display_table{
  display: table;
  width: 100%;
}

@mixin display_inline_block{
  display: inline-block;
  vertical-align: top;
}

// media queries
$breakpoint-0: 1910px; // s0
$breakpoint-1: 1700px; // s1
$breakpoint-2: 1500px; // s2
$breakpoint-3: 1300px; // s3
$breakpoint-11: 1200px; // s4
$breakpoint-12: 1400px; // s5
$breakpoint-4: 1050px; // m1
$breakpoint-5: 950px; // m2
$breakpoint-6: 767px; // m3
$breakpoint-7: 500px; // m4
$breakpoint-8: 370px; // m5
$breakpoint-9: 640px; // m6
$breakpoint-10: 475px; // m7

$breakpoint-min: 320px; // min


@mixin respond($media) {
  @if $media == s0 {
    @media only screen and (max-width: $breakpoint-0) { @content; }
  }
  @if $media == s1 {
    @media only screen and (max-width: $breakpoint-1) { @content; }
  }
  @if $media == s2 {
    @media only screen and (max-width: $breakpoint-2) { @content; }
  }
  @if $media == s3 {
    @media only screen and (max-width: $breakpoint-3) { @content; }
  }
  @if $media == s4 {
    @media only screen and (max-width: $breakpoint-11) { @content; }
  }
  @if $media == s5 {
    @media only screen and (max-width: $breakpoint-12) { @content; }
  }
  @if $media == m1 {
    @media only screen and (max-width: $breakpoint-4) { @content; }
  }
  @if $media == m2 {
    @media only screen and (max-width: $breakpoint-5) { @content; }
  }
  @if $media == m3 {
    @media only screen and (max-width: $breakpoint-6) { @content; }
  }
  @if $media == m4 {
    @media only screen and (max-width: $breakpoint-7) { @content; }
  }
  @if $media == m5 {
    @media only screen and (max-width: $breakpoint-8) { @content; }
  }
  @if $media == m6 {
    @media only screen and (max-width: $breakpoint-9) { @content; }
  }
  @if $media == m7 {
    @media only screen and (max-width: $breakpoint-10) { @content; }
  }


  @else if $media == min {
    @media only screen and (max-width: $breakpoint-min) { @content; }
  }

}

